import axios from "~/plugins/axios";
export default {
  // --- ALI REGISTRATION ---
  findUser(first_name, last_name, birth_date, fidelity_card) {
    let params = {
      first_name,
      last_name,
      birth_date
    };
    if (fidelity_card) {
      params.fidelity_card = fidelity_card;
    }
    return axios
      .get("/ebsn/api/ali-registration/search-crm", {
        params: params
      })
      .then(data => {
        return data.data;
      });
  },
  registerUser(userData) {
    return axios
      .post("/ebsn/api/ali-registration/register_user", userData)
      .then(response => {
        return response.data;
      });
  },
  updateUserDetail(user) {
    let url = "/ebsn/api/ali-registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateUserDisclaimer(registrationModules) {
    let url = "/ebsn/api/ali-registration/update-disclaimer";
    return axios
      .post(url, registrationModules)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
      });
  },
  updateUserEmail(user) {
    let url = "/ebsn/api/ali-registration/update_email";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
      });
  },
  getCountries() {
    return axios.get("/ebsn/api/geo/country-list", {}).then(data => {
      return data.data.data.results;
    });
  },
  provinceList(homeDeliveryOnly = true) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  getResetPasswordToken(email, fiscalCode, login) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email, fiscal_code: fiscalCode, login: login }
      })
      .then(data => {
        return data.data;
      });
  },
  setLotteryCode(code) {
    return axios
      .get("/ebsn/api/lottery/update-code", {
        params: {
          code: code
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  confirmRegistration(email, token) {
    let url = "/ebsn/api/ali-registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: token
        }
      })
      .then(response => {
        return response.data.response;
      });
  }

  // to be implemented
  // searchUser: { method: "GET", params: { action: "find_user" }, cache: false },
  // register_user: { method: "POST", params: { action: "register_user" }, cache: false },
  // registration_confirm: { method: "GET", params: { action: "registration_confirm" }, cache: false },

  // updateUserTicketEnabled: { method: "POST", params: { action: "update_user_ticket_enabled" }, cache: false },
};
