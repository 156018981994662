<template>
  <div class="registration-confirmed-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <v-card flat>
        <v-card-text>
          <CategoryTitle :category="category" />

          <v-alert type="success" v-if="registrationSucceded" class="mt-5">
            {{ $t("register.confirmed.text") }}
          </v-alert>
          <ResponseMessage
            class="mt-5"
            :response="response"
            v-else-if="!loading"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="!isAuthenticated"
            large
            depressed
            color="primary"
            min-width="200"
            @click="handleLogin"
            >{{ $t("register.confirmed.doLogin") }}</v-btn
          >
          <v-btn
            v-else
            large
            depressed
            color="primary"
            min-width="200"
            :to="{ name: 'HomeSite' }"
            >{{ $t("register.confirmed.goToHome") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.registration-confirmed-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";

import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";

import { mapGetters } from "vuex";
import aliRegistrationService from "@/service/aliRegistrationService";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle, ResponseMessage },
  mixins: [categoryMixins, loginMixins],
  data() {
    return {
      loading: true,
      email: null,
      token: null,
      registrationSucceded: false,
      response: {}
    };
  },
  ...mapGetters({
    isAuthenticated: "cart/isAuthenticated"
  }),
  methods: {
    async handleLogin() {
      let isLoggedIn = await this.doLogin("Home");
      if (isLoggedIn) {
        this.$router.push({
          name: "Home"
        });
      }
    },
    async confirmRegistration(email, token) {
      try {
        this.response = {};
        let res = await aliRegistrationService.confirmRegistration(
          email,
          token
        );

        if (res && res.status == 0) {
          this.registrationSucceded = true;
        } else {
          this.response = res;
        }
      } catch (err) {
        console.log(err);
        this.response = err;
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    console.log(this.$route.query);
    if (
      this.$route.query &&
      this.$route.query.e_mail &&
      this.$route.query.reg_control_code
    ) {
      this.login = this.$route.query.e_mail.replace(" ", "+");
      this.token = this.$route.query.reg_control_code;
      this.confirmRegistration(this.login, this.token);
    }
  }
};
</script>
